<template>
  <div
    ref="followDragContent"
    :class="['follow-text-content', {top: recordFrom, dragover: isDragover}]"
    @dragenter="handleDragEnter"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <atSomeone
      v-if="showAt"
      class="at-someone-content"
      :class="{active: record}"
      ref="atSomeone"
      :recordFrom="recordFrom"
      :from="from"
      :record="record"
      :placeholder="placeholder"
      :rejectBlur="true"
      :emojiTeleport="emojiTeleportName"
      :isMiniBtn="isMiniBtn"
      minHeight="28px"
      @updateRecordHtml="updateRecordHtml"
      @updataShowFlag="e => showFlag = e"
      @previewImg="(evt)=>$emit('previewImg', evt)"
      @editorFocus="editorFocusHandler"
      @editorBlur="editorBlurHandler"
      @updataEmptyStatus="updataEmptyStatus"
    />
    <template v-if="record || defaultShowBtn">
      <div style="padding: 0 10px;">
        <div v-for="(taskInfo , index ) in taskList" :key='index' class="task-wrap">
          <template v-if="!taskInfo?.isDeleted " >
            <div class="left-wrap">
              <a-tooltip  :content="taskInfo.completed?'点击重启任务':'点击完成任务'" >
                <div class="completed-box">
                  <!-- 222{{word-break: break-all;}} -->
                  <div :class="['completed',taskInfo.completed?'checked':'']"  @click="taskInfo.completed=!taskInfo.completed">
                    <i class="iconfont icon-Steps_1" style="color: #fff" />
                  </div>
                </div>
              </a-tooltip>
  
              <span :style="{ 'text-decoration': taskInfo.completed?'line-through':'','word-break': 'break-all'}">{{ taskInfo.summary }}</span>
  
              <a-tooltip v-if="taskInfo?.assignee?.length>0" :content="assigneeName(taskInfo.assignee)" >
                <span>
                  <a-divider direction="vertical"  style="margin: 0 8px;" />
                
                  <div class="avatar-cont-wrap" >
                    <div v-for="(item , index) in taskInfo.assignee" :key="index" class='avatar-cont'>
                      <!-- <div v-if="item.isCompleted"  class="completedUser"  >
                        <i class="iconfont icon-Steps_1" style="color: #fff" />
                      </div> -->
                      <img class="avatar-img" :src="item.avatar" width="32" alt="" />
                    </div>
                  </div>
                </span>
              </a-tooltip>
  
              
              <div v-if="taskInfo?.due" class="currenValueButton">
                <a-divider direction="vertical" style="margin: 0 8px;"/>
                <div v-if="taskInfo.due == dayjs().add(-1, 'day').format('YYYY-MM-DD')" style="color: red">
                  <i class="iconfont icon-icon_time_other"  />
                   <span>昨天 {{ getTimeText(taskInfo) }} 截止</span>
                    <a-tooltip v-if="taskInfo.reminders!=undefined" :content="getTipsText(taskInfo)"
                      ><i class="iconfont icon-icon_bell" />
                    </a-tooltip>
                  </div>
                  <template v-else-if="taskInfo.due == dayjs().add(0, 'day').format('YYYY-MM-DD')" >
                    <div :style="{'color': dayjs(taskInfo.due).add(taskInfo.dueTime, 'minute')<dayjs()?'red':'#3272fe'}" >
                      <i class="iconfont icon-icon_time_other"  />
                      <span>今天 {{ getTimeText(taskInfo)  }} 截止</span>
                      <a-tooltip v-if="taskInfo.reminders!=undefined" :content="getTipsText(taskInfo)">
                        <i class="iconfont icon-icon_bell"/>
                      </a-tooltip>
                    </div>
                </template>
                <!-- <div v-else-if="taskInfo.due == dayjs().add(0, 'day').format('YYYY-MM-DD')" style="color: #3272fe" >
                  <i class="iconfont icon-icon_time_other"  />
                  <span>今天 {{ getTimeText(taskInfo)  }} 截止</span>
                  <a-tooltip v-if="taskInfo.reminders!=undefined" :content="getTipsText(taskInfo)">
                    <i class="iconfont icon-icon_bell"/>
                  </a-tooltip>
                </div> -->
                <div v-else-if="taskInfo.due == dayjs().add(1, 'day').format('YYYY-MM-DD')">
                  <i class="iconfont icon-icon_time_other"  />
                  <span>明天 {{ getTimeText(taskInfo)}} 截止</span>
                  <a-tooltip v-if="taskInfo.reminders!=undefined" :content="getTipsText(taskInfo)">
                    <i class="iconfont icon-icon_bell" />
                  </a-tooltip>
                </div>
                <div v-else-if="taskInfo.due < dayjs().add(1, 'day').format('YYYY-MM-DD')" style="color: red">
                  <i class="iconfont icon-icon_time_other"  />
                  <span>
                    {{
                      dayjs(taskInfo.due).year()==dayjs().year()
                      ? dayjs((taskInfo).due).format('M月D日')
                        : dayjs((taskInfo).due).format('YYYY年M月D日')
                    }}
                    {{ getTimeText(taskInfo) }} 截止
                  </span>
                  <a-tooltip v-if="taskInfo.reminders!=undefined" :content="getTipsText(taskInfo)">
                    <i class="iconfont icon-icon_bell"/>
                  </a-tooltip>
                </div>
                <div v-else-if="taskInfo.due > dayjs().add(1, 'day').format('YYYY-MM-DD')">
                  <i class="iconfont icon-icon_time_other"  />
                  <span>
                    {{
                      dayjs(taskInfo.due).year()==dayjs().year()
                      ? dayjs((taskInfo).due).format('M月D日')
                        : dayjs((taskInfo).due).format('YYYY年M月D日')
                    }}
                    {{ getTimeText(taskInfo) }} 截止
                  </span>
                  <a-tooltip v-if="taskInfo.reminders!=undefined" :content="getTipsText(taskInfo)" >
                    <i class="iconfont icon-icon_bell"/>
                  </a-tooltip>
                </div>
              </div>
  
            </div>
            <div class="right-wrap">
              <a-tooltip  content="编辑" >
                <i class="iconfont icon-icon_edit" style="color: #1F2329" @click="taskEdit(index)" />
              </a-tooltip>
  
              <a-tooltip  content="删除" >
                <i class="iconfont icon-icon_delete" style="color: #1F2329" @click="taskDelete(index)"/>
              </a-tooltip>
            </div>
          </template>
          <template v-else>
            <div class="left-wrap task-delete" >
              <div class="completed-box">
                  <div class="completed" style="color:#86909C;" >
                    <i class="iconfont icon-Steps_1" style="color: #fff" />
                  </div>
                </div>
              此任务已在飞书任务中删除
            </div>
            <div class="right-wrap">
            <a-tooltip  content="删除" >
              <i class="iconfont icon-icon_delete" style="color: #1F2329" @click="feishuTaskDelete(index)"/>
            </a-tooltip>
          </div>
          </template>
        </div>
      </div>
      <!-- 分类容器 -->
      <div v-if="typeInfos?.length" class="record-tags-cont">
        <tagList
          :state="0"
          customClass="cus"
          :meta="{showAvatar: false}"
          :class="{showtagList: true, active: recordFrom}"
          ref="tagList"
          from="normal"
          :tags="typeInfos"
        />
        <!-- div占位 -->
        <div v-if="recordFrom" style="width: 30px; height: 20px;"></div>
        <!-- <template v-for="ii in recordTypeList" :key="ii.id">
          <template v-for="item in typeIds" :key="item">
            <span v-if="ii.id === item" class="tag-item">
              <span class="tag-item-name">{{ ii.name }}</span>
              <i class="iconfont icon-icon_close_med" @click="deleteTypeHandler(item)" />
            </span>
          </template>
        </template> -->
      </div>
      <!-- 文件容器 -->
      <ul v-if="messageFilesList?.length" class="file-content-ul 1">
        <tagList
          :state="0"
          :meta="{showAvatar: true}"
          :isFile="true"
          class="showtagList"
          ref="tagList"
          from="normal"
          :tags="messageFilesList"
        />
        <!-- <li
          class="file-items-li"
          v-for="(file, index) in messageFilesList"
          :key="index"
        >
          <fileIcon :file="file" size="16" />
          <span>{{ file.displayedLabel ?? file.fileName }}</span>
          <i class="iconfont icon-icon_close_med" @click="delMsgFile" />
        </li> -->
      </ul>
      <!-- 共创者容器 -->
      <div v-if="personList?.length" class="creater-cont">
        <tagList
          :state="0"
          :meta="{showAvatar: true}"
          :class="{showtagList: true, active: recordFrom}"
          ref="tagList"
          from="normal"
          :tags="personList"
        />
        <!-- div占位 -->
        <div v-if="recordFrom" style="width: 30px; height: 20px;"></div>
        <!-- <div class="avatar-item-cont" v-for="ii in personList" :key="ii.id">
          <div class="avatar-item">
            <div class="avatar-cont">
              <img v-if="ii.avatar" class="avatar-img" :src="ii.avatar" width="32" alt="">
              <span v-else class="avatar-text">{{ ii.name.slice(-2) }}</span>
            </div>
          </div>
          <span class="user-name">{{ ii.name }}</span>
          <i class="iconfont icon-icon_close_med" @click="deletePersonHandler(ii.id)" />
        </div> -->
      </div>
    </template>
    <div v-show="record || defaultShowBtn" ref="followTextBtnCont" class="follow-text-btn-cont" :class="{'warp': !recordFrom, mini: isMiniBtn}">
      <div class="left-options">
        <!-- 关联项目 -->
        <div v-if="recordFrom" class="releat-project">
          <a-trigger v-model:popup-visible="showTrigger" trigger="click" position="bl" :popup-offset="10" :unmount-on-close="false">
            <span class="releat-title" @click="editorFocusHandler"><i class="iconfont icon-xiangmu releat-icon" /><span :class="{active: selectedName}">{{ selectedName || '关联项目(必填)' }}</span><i class="iconfont icon-icon_d_arrow" /></span>
            <template #content>
              <div class="select-project-trigger-content">
                <a-input class="search-input" v-model.trim="projectName" placeholder="搜索项目" @input="handleSearch" />
                <a-spin :loading="searchLoading" style="height: 100%">
                  <ul v-if="dataOptions.length" class="options-data-ul">
                    <li class="data-select-option" v-for="item in dataOptions" :key="item.id" @click="selectDataChange(item)">
                      <img class="logo-img" v-if="item.logo" :src="item.logo"/>
                      <div class="logo-no-img" v-else>{{item.dataName && item.dataName[0]}}</div>
                      <div class="data-info">
                        <span class="data-name">{{item.dataName}}</span>
                        <span v-if="!isSingAsset" class="data-type">{{item.assetTypeName}}</span>
                      </div>
                    </li>
                  </ul>
                  <span v-else class="empty-cont">暂无数据</span>
                </a-spin>
              </div>
            </template>
          </a-trigger>
        </div>
        <a-divider v-if="recordFrom" direction="vertical" class="divider" />
        <!-- 分类 -->
        <a-popover v-model:popup-visible="showType" trigger="click" position="bl" class="cus-select-popover small">
          <a-tooltip :disabled="!isMiniBtn" content="分类">
            <span v-if="!isMiniBtn" class="add-tag-btn" @click="editorFocusHandler"><i class="iconfont icon-fenlei" /><span class="text">{{$t('plat_c.app_c.details_side.fenlei')}}</span></span>
            <span v-else class="add-tag-btn" @click="editorFocusHandler"><i class="iconfont icon-fenlei" /></span>
          </a-tooltip>
          <template #content>
            <template v-if="recordTypeList.length">
              <div class="record-type-popover-cont">
                <template v-for="ii in recordTypeList" :key="ii.id">
                  <div
                    v-if="ii.id != '9999'"
                    style="cursor: pointer;"
                    @click="selectHandler(ii)"
                    :class="['pop-select-item', typeIds.includes(ii.id) ? 'active-select' : '']"
                  >
                    <div class="pop-select-item-text">{{ ii.name }}</div>
                    <i v-if="typeIds.includes(ii.id)" class="iconfont icon-close-med" />
                  </div>
                </template>
              </div>
            </template>
            <small v-else class="empty-tips">请先选择项目/公司/基金/投资者</small>
          </template>
        </a-popover>
        <!-- 文件 -->
        <fileUpload
          ref="fileUpload"
          :from="from"
          :isAccont="true"
          v-model:fileList="messageFilesList"
          :dataId="infoObj.id || selectedId"
          :bCode="bCode"
          :singleLine="true"
          :editable="true"
          :defaultPopupVisible="false"
          :isMiniBtn="isMiniBtn"
          @update:fileList="doUpdate"
          @visible-change="fileVisibleChange"
          @click="editorFocusHandler"
        />
        <!-- 共创者 -->
        <addPerson
          :selfInfoId="selfInfoId"
          :persons="personList"
          @getSelectPerson="getSelectPerson"
          @click="editorFocusHandler"
        >
          <a-tooltip :disabled="!isMiniBtn" content="共创者">
            <span v-if="!isMiniBtn" class="add-creater-btn"><i class="iconfont icon-icon_add1" /><span class="text">{{$t('plat_c.app_c.details_side.gongchuangzhe')}}</span></span>
            <span v-else class="add-creater-btn" @click="editorFocusHandler"><i class="iconfont icon-icon_add1" /></span>
          </a-tooltip>
        </addPerson>
        <!-- style 异步传送的dom 宽度不会撑满 有bug，故写死宽度 -->
        <div class="emojiTeleport-wrap" :style="{width:!isMiniBtn?'64px':'30px'}">
          <div v-show="emojiTeleportName"  :id="emojiTeleportName"></div>
        </div>
        <div style="position: relative;">
          <a-tooltip v-if="!permLoading" :disabled="!isMiniBtn" content="任务">
            <span  v-if="!isMiniBtn" class="add-creater-btn" @click="handleShowTask"><i class="iconfont icon-icon_5_1" /><span class="text"></span>任务</span>
            <span v-else class="add-creater-btn" @click="handleShowTask"><i class="iconfont icon-icon_5_1" /></span>
          </a-tooltip>

          <a-drawer
            v-if="from == 'message'"
            class="task-drawer"
            :width="340"
            height="90vh"
            :visible="tastVisible"
            placement="bottom"
            unmount-on-close
            :footer='false'
            :mask-closable="false"
            @cancel="cancelTaskModal"
            unmountOnClose
          >
            <template #title>
            </template>
            <task v-if="tastVisible" :editInfo="taskEditInfo" from='message' @cancel="cancelTaskModal" @create="taskChange"/>
          </a-drawer>
          <a-modal
           v-else
            v-model:visible="tastVisible"
            class="task-modal"
            width="640px"
            unmount-on-close
            :align-center="false"
            :footer="false"
            :mask-closable="false"
            @cancel="cancelTaskModal"
          >
            <task v-if="tastVisible"  :editInfo="taskEditInfo" @cancel="cancelTaskModal" @create="taskChange"/>
          </a-modal>
        </div>
      </div>
      <div class="action-btn-content" v-if="from != 'message'">
        <!-- <a-button
          class="record-option-btn"
          v-if="!btnLoading"
          style="margin-right: 8px"
          type="outline"
          size="small"
          @click="cancleHandler"
        >{{$t('plat_c.app_c.general_btn.quxiao')}}</a-button> -->
        <a-button
          class="record-option-btn"
          type="primary"
          size="small"
          :loading="btnLoading"
          :disabled="(!record || isEmpty) && !taskList?.length"
          @click="submit"
        >{{ ['followRecordEdit'].includes(from) ? $t('plat_c.app_c.general_btn.queding') : $t('plat_c.app_c.details_side.fabu') }}</a-button>
      </div>
    </div>
    <!-- 拖拽 UI -->
    <div v-if="isDragover" class="record-drag-upload-mask" @dragover="handleDragEnter" @dragleave="handleDragLeave">
      <div class="tips-content">
        <img src="@/assets/img/detailsSide/dragIng.svg" alt="">
        <span>{{ dragUploading ? '上传中...' : '拖拽文件至此即可上传' }}</span>
      </div>
    </div>
    <!-- 没任务权限 -->
    <a-modal
      v-model:visible="showNoTaskAuthPop"
      class="public-modal"
      okText="知道了"
      title=" "
      :width="from != 'message' ? '640px' : '100%'"
      hideCancel
    >
      <TaskNoAuth />
    </a-modal>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import dayjs from 'dayjs'
import fileUpload from '@/views/components/FileFieldComp'
import atSomeone from '@/components/atSomeone/index.vue'
import task from '@/components/task/index.vue'
import addPerson from '@/views/components/addPerson/index.vue'
import fileIcon from '@/components/fileIcon.vue'
import {
  followRecordSearchData,
  taskQueryList,
} from '@/services/investment/followRecord.js'
import _ from 'lodash';
import { getDataTypeByBusinessCode } from '@/utils/enums.js';
import { transformTaskData ,getTimeText, getTipsText,assigneeName} from '@/utils/task.js';
import tagList from '@/components/form/fields/tagSelect/tagList.vue'
import { mapState } from 'vuex'



export default {
  name: 'ReleaseRecord',
  components: {
    fileIcon,
    fileUpload,
    atSomeone,
    addPerson,
    tagList,
    task,
    TaskNoAuth: defineAsyncComponent(() => import('@/views/noAuth/taskAuth.vue')),
  },
  props: {
    bCode: String,
    appType: String,
    selfInfoId: String,
    releatDataId: String,
    releatDataName: String,
    from: {
      type: String,
      default: ''
    },
    disabled:{
      type: Boolean,
      default: false
    },
    localMemory:{ // 是否需要本地缓存已输入但是未发布的跟进记录
      type: Boolean,
      default: false
    },
    //已有信息
    msg:{
      type: String,
      default: ''
    },
    typeId:{
      type: String,
      default: ''
    },
    infoObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    messageFiles:{
      type: Object,
      default: ()=>{
        return []
      }
    },
    // 共创者
    creators:{
      type: Object,
      default: ()=>{
        return []
      }
    },
    //文件类型
    typeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    //记录分类
    recordTypeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    //是否需要默认关联数据
    needRelateData:{
      type: Boolean,
      default: false,
    },
    // 台账标识
    recordFrom: {
      type: String,
      default: ''
    },
    // 默认显示底部按钮（分类，文件，共创者，发布，取消）
    defaultShowBtn: {
      type: Boolean,
      default: false,
    },
    // 是否只开启一个资产
    isSingAsset: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '添加项目跟进，记录重要进展',
    },
     emojiTeleportName: {
      type: String,
      default: ''
    },
    tasks:{
      type: Object,
      default: ()=>{
        return []
      }
    }
  },
  watch:{
    localMemory: {
      immediate: true,
      handler(val) {
        // 特殊处理 回写本地缓存的跟进记录，因为不这么写会有问题
        if (!val) return
        this.showAt = false
        setTimeout(() => {
          this.showAt = true
        }, 500);
      }
    },
    messageFiles:{
      deep: true,
      immediate: true,
      handler(v){
        if (!v || !v.length) return
        this.from === 'message' && v.forEach(item => {
          item.fileBusinessType = '9999'
        })
        v = v.filter(item => !item.isAtCloud) // 过滤掉@云文档
        this.messageFilesList.splice(0, this.messageFilesList.length, ..._.cloneDeep(v));
        this.cloudFileList = v
      }
    },
    msg:{
      immediate: true,
      handler(v){
        // <at user_id="1483260254983553024">@徐东亮</at> 自己 <at user_id="123">@徐东亮</at>
        // /<at\s+user_id="[0-9]+">.*?<\/at>/
        // <span class="at-text" data-id="1483260254983553024" contenteditable="false" style="color: rgb(62, 116, 202);">@徐东亮</span>
        // console.log(v);
        let tmpRecord = v.replace(/<at\s+user_id="([0-9a-z_]+)">(.*?)<\/at>/ig, function(a,b,c){
          return `<span class="at-text" data-id="${b}" contenteditable="false" style="color: rgb(62, 116, 202);">${c}</span>`
        });
        this.record = tmpRecord;
        // this.record = v
      }
    },
    typeId: {
      immediate: true,
      handler(v){
        this.typeIds = [...this.typeIds, ...v]

        this.typeInfos = []
        this.typeIds.forEach(item => {
          this.recordTypeList.forEach(ii => {
            if (ii.id == item) {
              ii.label = ii.name
              ii.color = '#DDEAFF'
              this.typeInfos.push(ii)
            }
          })
        })
      }
    },
    creators: {
      deep: true,
      immediate: true,
      handler(v){
        this.personList = []
        v.forEach(item => {
          this.personList.push({
            id: item.creator || item.userId || item.id,
            name: item.userName || item.name,
            avatar: item.avatar,
            label: item.userName || item.name,
            color: '#F3F4F3',
          })
        })
      }
    },
    messageFilesList: {
      deep: true,
      immediate: true,
      handler(v){
        if (v && v.length) {
          v.forEach(item => {
            item.label = item.fileName
            item.color = '#fff'
          })
        }
      }
    },
    dragUploading(val) {
      if (val) return
      this.isDragover = false
    },
    tasks: {
      immediate: true,
      handler(val) {
        // 特殊处理 回写本地缓存的跟进记录，因为不这么写会有问题
        if (!val.length) return
        this.taskList= transformTaskData(val)
      }
    },
  },
  data() {
    return {
      dayjs,
      keywords: '',
      record: '',
      relatedUser: [],
      messageFilesList: [],
      uploadFileList: [],
      cloudFileList: [],
      // placeholder: this.$t('plat_c.app_c.details_side.tianjiajilu_placeholder'),
      // userList: [],
      relatedIds: [],
      nativeContent: '',
      msgContent: '',
      btnLoading: false,
      showType: false,
      typeIds: [],
      typeInfos: [],
      morePersonPop: false,
      personList: [],
      showFlag: false,
      atCloudFiles: [],
      dataOptions:[], // 关联项目列表
      showTrigger: false,
      searchLoading: false,
      projectName: '',
      selectedId: '',
      selectedName: '',
      selectedAssetTypeId: '',
      selectedBusinessCode: '',
      dataType: '',
      showAt: true,
      timeout: null,
      memoryHtml: '',
      isEmpty: true,
      isMiniBtn: false,
      permLoading: false,
      noTaskAuth: false,
      showNoTaskAuthPop: false,
      tastVisible:false,
      dragUploading: false,
      isDragover: false,
      dragTime: null,
      taskList:[],
      taskEditIndex: -1,
      taskEditInfo:{},
      deleteTaskId:[],
      deleteMsTaskId:[],
    }
  },
  computed: {
    ...mapState([ 'tenantInfo']),
    assigneeName(){
      return function(assignee){
        return assigneeName(assignee)
      }
    },
    getTimeText (){
      return function(info){
        return getTimeText(info)
      }
    },
    getTipsText() {
      return function(info){
        return getTipsText(info)
      }
    },
  },
  created() {
    this.handleCheckTaskPermission()
    if (this.recordFrom) {
      this.projectName = this.releatDataName
      this.handleSearch(this.releatDataName || '') // 因为接口返回的是100条数据，会有问题，所以需要用名称查一下
    }
  },
  mounted() {
    const investSubject = this.$refs.followTextBtnCont
    useResizeObserver(investSubject, (entries) => {
      const entry = entries[0]
      const { width } = entry.contentRect
      this.isMiniBtn = false
      if (width < 435) {
        this.isMiniBtn = true
      } 
    })
  },
  methods: {

    handleShowTask() {
      let name;
      // 台账
      if(this.recordFrom == 'listAccount') {
        switch(this.appType) {
          case 'before':
            name = '投前台账跟进记录-任务'
            break;
          case 'after':
            name = '投后台账跟进记录-任务'
            break;
          default:
            name = '投前台账跟进记录-任务'
            break;
        }
      } else {
        // 详情页
        switch(this.appType) {
          case 'before':
            name = '投前详情跟进记录-任务'
            break;
          case 'after':
            name = '投后详情跟进记录-任务'
            break;
          case 'fund':
            name = '基金详情跟进记录-任务'
            break;
          case 'lpInvestor':
            name = '投资者详情跟进记录-任务'
            break;
          default:
            name = '投前详情跟进记录-任务'
            break;
        }
      }
      _czc && _czc.push(['_trackEvent', this.tenantInfo?.name, '点击', name])
      this.showNoTaskAuthPop = false
      if (this.noTaskAuth) {
        return this.showNoTaskAuthPop = true
      }
      this.taskEditIndex=-1;
      this.taskEditInfo={}
      this.tastVisible = true
    },
    handleCheckTaskPermission() {
      if (this.permLoading) return
      this.permLoading = true
      taskQueryList().then(res => {
        if (res?.response?.data?.code === 99991672) {
          this.noTaskAuth = true
        } else {
          this.noTaskAuth = false
        }
      }).catch(() => {
        this.noTaskAuth = true
      }).finally(() => {
        this.permLoading = false
      })
    },
    cancelTaskModal(){
      this.tastVisible=false;
      this.taskEditIndex=-1;
      this.taskEditInfo={}
    },
    taskEdit(index){
      this.taskEditIndex=index
      this.taskEditInfo=this.taskList[index]
      this.tastVisible=true
    },
    taskDelete(index){
      if(this.taskList[index].id){
        this.deleteTaskId.push(this.taskList[index].id)
      }
      this.taskList.splice(index,1)
    },
    feishuTaskDelete(index){
      this.deleteMsTaskId.push(this.taskList[index].id)
      this.taskList.splice(index,1)
    },
    taskChange(taskInfo){
      if(this.taskEditIndex>-1){
        this.taskList[this.taskEditIndex]=taskInfo
      }else{
        this.taskList.push(taskInfo)
      }
      this.tastVisible=false
    },
    // 拖拽上传
    handleDragEnter(event) {
      event.preventDefault()
      if (this.dragTime) {
        clearTimeout(this.dragTime)
        this.dragTime = null
      }
      this.isDragover = true
    },
    handleDragLeave() {
      if (this.dragUploading) return
      this.dragTime = setTimeout(() => {
        this.isDragover = false
        clearTimeout(this.dragTime)
        this.dragTime = null
      }, 300)
    },
    handleDrop(event) {
      event.preventDefault()
      if (this.dragUploading) return
      const files = event.dataTransfer.files
      if (files?.length > 1) {
        this.isDragover = false
        return this.$message.warning('请上传单个文件')
      }
      if (!event.dataTransfer.types.includes('Files')) {
        this.isDragover = false
        return this.$message.warning('仅支持文件类型')
      }
      if (files?.length) {
        const file = files[0]
        if (/^image\//.test(file.type)) {
          // 图片
          let tmpFileName = 'img_' + file.lastModified;
          let reader = new FileReader();
          reader.onload = (event)=>{
            let src = event.target.result;
            this.$refs.atSomeone?.insertImg(src, tmpFileName);
            this.$refs.atSomeone.handleShowAIImgPop(file)
            this.$refs.atSomeone.setPhotoCacheMap(file, tmpFileName)
            this.dragUploading = false
            this.isDragover = false
          }
          reader.readAsDataURL(file)
        }
        else {
          // 文件
          if (!file.type) { // 不支持文件夹  
            this.isDragover = false
            return this.$message.error('不支持该类型文件')
          }
          this.handleUpLoadFile(file)
        }
      }
    },
    handleUpLoadFile(file) {
      // const fileObj = {
      //   fileName: file.name,
      //   fileTags: [],
      //   fileToken: 'LR5vbEquboObtnxOZW0cjycTnjd',
      //   fileType: 'file',
      //   label: file.name,
      //   needRelate: 1,
      //   source: 'upload',
      //   type: 'userLocalFile'
      // }
      // this.messageFilesList.push(fileObj)
      // this.doUpdate([fileObj])
      this.dragUploading = true
      this.$refs.fileUpload.handleCustomRequest({
        fileItem: {
          file: file
        }
      })
    },

    updataEmptyStatus(isEmpty) {
      this.isEmpty = isEmpty
    },
    autoFocusHandler() {
      this.$refs.atSomeone.focusHandler()
    },
    editorFocusHandler() {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
        return
      }
      this.$emit('editorFocus')
    },
    editorBlurHandler() {
      this.timeout = setTimeout(() => {
        this.$emit('editorBlur')
        clearTimeout(this.timeout)
        this.timeout = null
      }, 300);
    },
    delVal(index) {
      this.typeInfos.splice(index, 1)
      this.typeIds.splice(index, 1)
    },
    fileVisibleChange(val) {
      if (val && !this.selectedId && this.recordFrom) {
        this.$nextTick(() => {
          this.$refs.fileUpload.changePopVisible()
          this.$message.warning('请先选择关联项目！')
        })
      }
    },
    selectDataChange(item) {
      this.selectedId = item.id
      this.selectedName = item.dataName
      this.selectedAssetTypeId = item.assetTypeId
      this.selectedBusinessCode = item.businessCode
      this.dataType = getDataTypeByBusinessCode(item.businessCode)
      this.showTrigger = false
    },
    handleSearch: _.debounce(function(keyword) {
      this.searchLoading = true
      followRecordSearchData({
        data:{
          dataName: keyword,
          appType: this.appType,
        }
      }).then(res => {
        this.dataOptions = res || []
        if (this.releatDataId && this.dataOptions.length) {
          let obj = this.dataOptions.find(item => {
            return item.id == this.releatDataId
          })
          this.selectedName = obj.dataName
          this.selectedId = obj.id
          this.selectedBusinessCode = obj.businessCode
          this.dataType = getDataTypeByBusinessCode(obj.businessCode)
        }
      }).finally(() => {
        this.searchLoading = false
      })
    }, 200),
    getSelectPerson(person) {
      this.personList = person || []
      this.$emit('getCreators', this.personList)

      this.setLocalRecord()
    },
    deletePersonHandler(id) {
      const index = this.personList.findIndex(item => {
        return item.id === id
      })
      this.personList.splice(index, 1)
      this.morePersonPop = false
      this.$emit('getCreators', this.personList)
    },
    deleteTypeHandler(id) {
      const index = this.typeIds.findIndex(item => {
        return item === id
      })
      this.typeIds.splice(index, 1)
      this.typeInfos.splice(index, 1)
    },
    doUpdate(list) {
      this.uploadFileList = []
      this.cloudFileList = []
      list && list.forEach(item => {
        if (!item.fileTags) {
          // 处理fileTags: null
          item.fileTags = []
        }
        if ((item.state && (item.state === 'prepare' || item.state === 'uploading')) || item.type === 'userLarkDoc') {
          item.isNewUpload = true
          this.uploadFileList.push(item)
        } else {
          item.needRelate = 1
          this.cloudFileList.push(item)
        }
      })
      this.$emit('getFileParamList', list)
      this.$emit('updataFileList', this.uploadFileList, this.cloudFileList)

      this.setLocalRecord()
      this.dragUploading = false
    },
    selectHandler(item) {
      const index = this.typeIds.findIndex(ii => {
        return item.id == ii
      })
      if (index < 0) {
        this.typeIds.push(item.id)
        item.label = item.name
        item.color = '#DDEAFF'
        this.typeInfos.push(item)
      } else {
        this.typeIds.splice(index, 1)
        this.typeInfos.splice(index, 1)
      }
      // this.showType = false
      this.$emit('getTypeId', this.typeIds)

      this.setLocalRecord()
    },
    cancleHandler() {
      this.reset()
      this.$emit('cancleSub')

      const storageKey = this.infoObj.id + this.selfInfoId
      localStorage.removeItem(storageKey)
    },
     //供外部使用
    getParamsForMessage(){
      // 获取@云文档信息
      this.atCloudFiles = []
      const htmlDom = new DOMParser().parseFromString(this.record, 'text/html');
      const domArr = Array.prototype.slice.call(htmlDom.getElementsByTagName('span')) // 将 getElementsByTagName 获取到的类数组转换为数组
      if (domArr.length) {
        domArr.forEach(item => {
          const dateInfoString = item.dataset.info ? decodeURIComponent(item.dataset.info) : ''
          const dataInfo = dateInfoString ? JSON.parse(dateInfoString) : null
          if (!dataInfo) return
          if (dataInfo.type === 'file') {
            this.atCloudFiles.push({
              dataId: this.infoObj.id || this.selectedId,
              fileName: dataInfo?.fileInfo?.fileName,
              fileToken: dataInfo?.fileInfo?.fileToken,
              fileTags: dataInfo?.fileInfo?.fileTags ? [dataInfo?.fileInfo?.fileTags] : ['9999'],
              fileType: dataInfo?.fileInfo?.fileType,
              isAtCloud: true,
            })
          }
        })
      }
      // task参数
      let taskData=this.getTaskList()
      return {
        record: this.record,
        nativeContent: this.nativeContent,
        msgContent: this.msgContent,
        messageFilesList: this.messageFilesList ?? [],
        uploadFileList: this.uploadFileList,
        cloudFileList: this.cloudFileList,
        relatedIds: this.relatedIds,
        atCloudFiles: this.atCloudFiles,
        pastePhotoMap: this.$refs?.atSomeone?.getPastePhotoMap() ?? [],
        task:taskData?.task||undefined,
        taskFile:taskData?.taskFile||undefined,
      }
    },
    replaceHtmlTags(htmlString, needNum = false) {
      // return htmlString.replace(/<li>/gi, () => `${count++}`).replace(/<\/li>/gi, '\n');

      // 替换 ol 下的 li 为数字
      let olRegex = /<ol>([\s\S]*?)<\/ol>/g;
      htmlString = htmlString.replace(olRegex, function(match, p1) {
        let count = 1
        let temp = `${p1.replace(/<li>/g, (match, p1) => `\n${count++}. `)}`
        temp = temp.replace(/<\/li>/gi, '\n')
        return temp
      });
    
      // 替换 ul 下的 li 为连字符
      let ulRegex = /<ul>([\s\S]*?)<\/ul>/g;
      htmlString = htmlString.replace(ulRegex, function(match, p1) {
        let temp = `${p1.replace(/<li>/g, (match, p1) => `\n- `)}`
        temp = temp.replace(/<\/li>/gi, '\n')
        return temp
      });

      // 替换加粗
      htmlString = htmlString.replace(/<strong>/gi, '**').replace(/<\/strong>/gi, '**')
      // 替换斜体
      htmlString = htmlString.replace(/<em>/gi, '*').replace(/<\/em>/gi, '*')
      // // 替换删除线
      htmlString = htmlString.replace(/<s>/gi, '~~').replace(/<\/s>/gi, '~~')
      // 替换下划线 不生效
      // htmlString = htmlString.replace(/<u>/gi, '_').replace(/<\/u>/gi, '_')
      // 去掉默认 p 标签
      htmlString = htmlString.replace(/<p>/gi, '').replace(/<\/p>/gi, '')
    
      return htmlString;
    },
    updateRecordHtml(html, text, memoryHtml, msgContent) {
      this.memoryHtml = memoryHtml
      this.record = html
      this.nativeContent = text
      // this.msgContent = this.replaceHtmlTags(html)
      this.msgContent = msgContent
      // 获取@人的id和@云文档信息
      this.relatedIds = []
      this.atCloudFiles = []
      const htmlDom = new DOMParser().parseFromString(html, 'text/html');
      const domArr = Array.prototype.slice.call(htmlDom.getElementsByTagName('span')) // 将 getElementsByTagName 获取到的类数组转换为数组
      if (domArr.length) {
        domArr.forEach(item => {
          const dateInfoString = item.dataset.info ? decodeURIComponent(item.dataset.info) : ''
          const dataInfo = dateInfoString ? JSON.parse(dateInfoString) : null
          if (!dataInfo) return
          if (dataInfo.type === 'user') {
            this.relatedIds.push(dataInfo.userInfo.id)
          }
          if (dataInfo.type === 'file') {
            this.atCloudFiles.push({
              dataId: this.infoObj.id || this.selectedId,
              fileName: dataInfo?.fileInfo?.fileName,
              fileToken: dataInfo?.fileInfo?.fileToken,
              fileTags: dataInfo?.fileInfo?.fileTags ? [dataInfo?.fileInfo?.fileTags] : ['9999'],
              fileType: dataInfo?.fileInfo?.fileType,
              isAtCloud: true,
            })
          }
        })
      }
      // console.log('relatedIds', this.relatedIds)
      // console.log('atCloudFiles', this.atCloudFiles)

      if (['followRecordEdit'].includes(this.from)) {
        this.$emit('updateRecordHtml', html, text, msgContent)
      }

      this.setLocalRecord()
    },
    setLocalRecord() {
      // 跟进记录记住未发布的输入数据
      if (this.localMemory && !this.recordFrom) {
        // let tempHtml = this.memoryHtml // 需要记录图片则打开注释，同时注释掉下面的deArray替换图片代码，但是目前记录的图片发布后会是裂图 不知道为啥
        let tempHtml = this.record
        let imgReg = /<img.*?(?:>|\/>)/gi
        let deArray = tempHtml.match(imgReg) || []
        deArray.forEach(item => {
          tempHtml = tempHtml.replace(item, '')
        })

        const recordsInfo = {
          records: tempHtml,
          typeIds: this.typeIds,
          messageFilesList: this.messageFilesList,
          personList: this.personList,
        }

        // 详情页的跟进记录需要缓存
        const storageKey = this.infoObj.id + this.selfInfoId
        localStorage.setItem(storageKey, JSON.stringify(recordsInfo))
      }
    },
    mentionClear() {
      this.relatedIds = []
      this.relatedUser = []
    },
    reset() {
      this.record = ''
      this.messageFilesList = []
      this.typeIds = []
      this.typeInfos = []
      this.personList = []
      this.uploadFileList = []
      this.cloudFileList = []
      this.btnLoading = false
      this.atCloudFiles = []
      this.selectedId = ''
      this.selectedName = ''
      this.projectName = ''
      this.selectedAssetTypeId = ''
      this.selectedBusinessCode = ''
      this.$refs.atSomeone.reset()
      this.taskList = []
      this.taskEditIndex = -1
      this.taskEditInfo = {}

      if (this.recordFrom) {
        this.handleSearch('')
      }
    },
    popupVisibleChange(e, file) {
      file.showSelect = e
    },
    getList(val) {
      this.uploadFileList.push(...val)
    },
    getCloudList(val) {
      this.cloudFileList.push(...val)
    },
    delMsgFile(index){
      this.messageFilesList.splice(index, 1)
    },
    delLocalFile(index) {
      this.uploadFileList.splice(index, 1)
    },
    delCloudFile(index) {
      this.cloudFileList.splice(index, 1)
    },
    open(url) {
      window.open(url, '_blank')
    },
    getTaskList() {
       
      let task=[]
     let taskFile=[]
     if(this.taskList.length){
        this.taskList.map((taskInfo) =>{
          if(taskInfo.isDeleted){
            return
          }
          
        let fileFilter=taskInfo.fileList.filter((item) =>item.file)
        // if(fileFilter.length)
        taskFile.push(fileFilter.length?fileFilter:[])
        
        let info={}
        info.summary=taskInfo.summary//任务标题
        info.mode=2//任务完成模式, 1 - 会签任务; 2 -单签任务
        if(taskInfo.id)info.id=taskInfo.id
        if(taskInfo.listGuid)info.listGuid=taskInfo.listGuid
        if(taskInfo.deleteFileGuid.length)info.deleteFileGuid=taskInfo.deleteFileGuid
        // 任务来源
        info.origin= {
          platform_i18n_name: {
            zh_cn: "投资云", //来源名称
            en_us: "投资云"
          },
          href: {
              "url": `https://applink.feishu.cn/client/web_app/open?appId=${this.$route.query.aid}&businessCode=${this.selectedBusinessCode ||this.bCode|| this.infoObj.businessCode}&dataid=${this.selectedId || this.infoObj.id }&reload=true`, //项目链接
              "title": this.selectedName || this.infoObj.name || this.infoObj.projectName || this.infoObj.fundName 
          }
        }

        if(taskInfo.due){
          info.due={
            "timestamp": dayjs(taskInfo.due).minute(taskInfo.dueTime||0).valueOf(),
            "is_all_day": taskInfo.dueTime>-1? false:true
          }
        }
        // 任务完成时间
        if(taskInfo.completed){
          info.completed_at=dayjs().valueOf()
        }else{
          info.completed_at=0
        }
        // 如果提醒是负数, 那就不传 eminders":null
        if(taskInfo.reminders>=0){
          info.reminders=[{
            "relative_fire_minute": taskInfo.reminders 
          }]
        }
        // members
        
        if(taskInfo.assignee.length||taskInfo.follower.length){
          let members=[]
          taskInfo.assignee.map((item)=>{
            members.push({
                id:item.orginId || undefined,
                userId: item.id, //人员openid
                type: "user", //类型
                role: "assignee" //负责人或者关注人 "assignee"或者"follower"
             })
          })
          taskInfo.follower.map((item)=>{
            members.push({
              id:item.orginId || undefined,
                userId: item.id, //人员openid
                type: "user", //类型
                role: "follower" //负责人或者关注人 "assignee"或者"follower"
             })
          })
          info.members=members
          // 对比删除的人员
          let deleteMembers=[]
          taskInfo?.orginMembers?.map((item)=>{
            if(
              (item.role=='assignee'&&!taskInfo.assignee.find(ff => item.userId == ff.userId))||
              (item.role=='follower'&&!taskInfo.follower.find(ff => item.userId == ff.userId))
            ){
              
              deleteMembers.push({
                id:item.id || undefined,
                userId: item.userId, //人员openid
                type: "user", //类型
                role: item.role//负责人或者关注人 "assignee"或者"follower"
             })
            }
          })
          if(deleteMembers.length){
            info.deleteMembers=deleteMembers
          }
        }
        task.push(info)
      })
      
      }
      return {
        task,taskFile
      }
    },
    async submit() {
      const uploadFileNum = this.$refs.fileUpload.getWaitUploadFileNum()
      if (uploadFileNum) return this.$message.warning('文件正在上传中，请等待！')
      if (!this.nativeContent.trim().length && !this.taskList?.length) {
        this.$message.warning(this.$t('plat_c.app_c.general_toast.neirongweikong'))
        return
      }
      if (!this.selectedId && this.recordFrom) {
        return this.$message.warning(this.$t('请选择关联项目'))
      }
      let creators = []
      this.personList.forEach((item, index) => {
        creators.push({
          userId: item.id,
          orderNum: index + 1
        })
      })
      this.btnLoading = true
      let files = []
      let fileTokens = []
      if (this.uploadFileList.length) {
        files = this.uploadFileList.map((file) => {
          return {
            dataId: this.infoObj.id || this.selectedId,
            fileTags: file.fileTags,
            fileName: file.fileName,
            fileToken: file.fileToken,
            fileType: file.fileType,
            businessCode: this.bCode
          }
        })
      }
      this.cloudFileList.forEach(item => {
        fileTokens.push({
          dataId: this.infoObj.id || this.selectedId,
          fileName: item.fileName,
          fileToken: item.fileToken,
          fileType: item.fileType,
          fileTags: item.fileTags,
          isAtCloud: false, // 标识不是@云文档文件
        })
      })
      // @云文档
      if (this.atCloudFiles.length) {
        this.atCloudFiles.forEach(item => {
          item.dataId = this.infoObj.id || this.selectedId
        })
        fileTokens = [...fileTokens, ...this.atCloudFiles]
      }
      /**
       * 过滤掉重复（当一个云文件同时被@和添加时，只需要保留isAtCloud = false的，cloudFiles中这个文件只有一条）
       * 这里正好利用了_.uniqBy的机制处理了（因为上面@云文档的文件是合并到了最后面，刚好_.uniqBy去重是保留首次出现的目标）
       */
      fileTokens = _.uniqBy(fileTokens, 'fileToken')
      let pastePhotoMap = this.$refs.atSomeone.getPastePhotoMap();
      const data = {
        record: this.record,
        msgContent: this.msgContent, // 用来飞书消息卡片显示
        nativeContent: this.nativeContent,
        relatedUser: this.relatedIds.join(),
        localFiles: files,
        cloudFiles: fileTokens,
        typeIds: this.typeIds,
        creators: creators,
        assetTypeId:this.selectedAssetTypeId||this.infoObj.assetTypeId||null,
        deleteTaskId:this.deleteTaskId || [],
        deleteMsTaskId:this.deleteMsTaskId ||[],
        pastePhotoMap
      }
      // task参数
      let taskData=this.getTaskList()
      let task=taskData?.task ||[]
      let taskFile=taskData?.taskFile ||[]
      if(task?.length)data.task=task
      this.$emit('releaseHandler', data, this.selectedId, this.dataType, this.selectedBusinessCode || this.bCode,taskFile)
      this.editorBlurHandler()
    },
  },
}
</script>

<style lang="scss">
.task-modal , .task-drawer{
  .arco-modal-header , .arco-drawer-header{
    height: 50px;
    border-bottom:0;
    .arco-modal-close-btn , .arco-drawer-close-btn{
      font-size: 18px;
      position: absolute;
      right: 24px;
      .arco-icon-hover {
        &::before {
          width: 26px;
          height: 26px;
          border-radius: 6px;
        }
      }
    }
  }
  .arco-modal-body{
    padding: 0 24px;
  }
}
.select-project-trigger-content {
  width: 268px;
  height: 40vh;
  border-radius: 4px;
  border: 1px solid #DBDFE1;
  background: #fff;
  .empty-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: #86909C;
  }
  .options-data-ul {
    width: 100%;
    height: calc(100% - 38px);
    overflow-y: auto;
    padding: 4px 0 4px 4px;
  }
  .search-input {
    border: 0;
    border-bottom: 1px solid #DBDFE1;
    border-radius: 4px 4px 0 0;
    padding: 0 12px;
    &:hover {
      border-color: #DBDFE1;
    }
    &:focus-within {
      border-color: #DBDFE1;
    }
    .arco-input {
      padding: 6px 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.showtagList {
  width: 100%;
  &.active {
    width: calc(100% - 30px);
  }
  :deep(.cus) {
    height: 20px;
    line-height: 20px;
    border-radius: 4px;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}
.data-select-option{
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 48px;
  line-height: 48px;
  margin-bottom: 4px; 
  cursor: pointer;
  border-radius: 4px;
  transition: all .2s;
  &:hover {
    background: rgba(31, 35, 41, 0.08);
  }
  .data-info {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }
  :deep(.arco-select-option-content) {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .logo-img{
    width: 32px;
    height: 32px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
  }
  .logo-no-img{
    width: 32px;
    height: 32px;
    border-radius: 5px;
    background: #99adff;
    color: white;
    line-height: 32px;
    text-align: center;
  }
  .data-name{
    flex: 1;
    line-height: 20px;
    font-size: 14px;
    color: #313b45;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0 !important;
  }
  .data-type{
    line-height: 18px;
    font-size: 12px;
    color: #959AA2;
  }
}
.file-content-ul {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
  .file-items-li {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    .file-icon {
      margin-right: 4px;
    }
    .icon-icon_close_med {
      cursor: pointer;
      font-size: 12px;
      color: #646A73;
      margin-left: 4px;
      position: relative;
      top: 1px;
    }
  }
}
:deep(.uploader-trigger) {
  width: auto;
  flex-shrink: 0;
  margin-right: 4px;
  border-radius: 6px;
  background: none;
  transition: all .2s;
  &:hover {
    background-color: rgba(31, 35, 41, 0.08);
  }
  .trigger-empty {
    color: #1F2329;
    font-size: 14px;
    &.account {
      width: auto;
      height: 28px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      padding: 0 6px;
      .iconfont {
        color: #646A73;
        margin-right: 6px;
        background: none;
      }
    }
  }
}
.add-tag-btn {
  flex-shrink: 0;
  padding: 0 6px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
  color: #1F2329;
  margin-right: 4px;
  border-radius: 6px;
  background: none;
  transition: all .2s;
  &:hover {
    background-color: rgba(31, 35, 41, 0.08);
  }
  .iconfont {
    font-size: 16px;
    color: #646A73;
  }
  .text {
    margin-left: 6px;
  }
}
.add-creater-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0 6px;
  border-radius: 6px;
  transition: all .2s;
  cursor: pointer;
  background: none;
  transition: all .2s;
  &:hover {
    background-color: rgba(31, 35, 41, 0.08);
  }
  .icon-icon_add1 , .icon-icon_5_1{
    color: #646A73;
  }
  color: #1F2329;
  .text {
    margin-left: 6px;
  }
}

.task-wrap{
  display: flex;
  padding:0 4px;
  margin-top: 8px;
  // background: #ECEEEE;
  &:hover{
    border-radius: 6px;
    background: #ECEEEE;
    .right-wrap{
      display:  inline-flex;
      line-height: 24px;
       height: 24px;
    }
    .avatar-cont{
      border-color: #ECEEEE!important;;
    }
  }
  .left-wrap.task-delete{
    color:#86909C;
    .completed{
      cursor: auto!important; 
      background: #F2F3F5;
    }
  }
  .left-wrap{
    flex:1;
    // line-height: 22px;
    line-height: 24px;
    // vertical-align:middle;
    display: inline-block;

    .arco-divider-vertical{
      vertical-align: baseline;
    }
    .completed-box{
      height: 24px;
      border-radius: 40px;
      margin-left: 4px;
      vertical-align: bottom;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .completed{
        margin-right: 6px;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: inherit;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #8E969E;
        .icon-Steps_1{
          display: none;;
        }
      }
      .completed.checked{
        border:0;
        background: #35BE4B;
        .icon-Steps_1{
          display: block;
          font-size: 10px;
        }
      }
    }
    .avatar-cont-wrap{
      display: inline-block;
      height: 24px;
      border-radius: 40px;
      margin-left: 4px;
      vertical-align: bottom;
      position: relative;
      .completedUser{
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: inherit;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid #8E969E;
        border:0;
        background: #35BE4B;
        bottom:0;
        right:0;
        .icon-Steps_1{
          display: block;
          font-size: 6px;
        }
      }
      .avatar-cont{
        display: inline-block;
        border: 2px solid #fff;
        margin-left: -6px;
        border-radius: 50px;
        line-height:24px;
        height: 24px;
      }
      img{
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    .date-text{
      margin: 0 6px;
    }
    .currenValueButton{
      display: inline-block;
      line-height: 24px;
      >div{
        display: inline-flex;
        align-items: center;
        > span{
          margin:0 6px;
        }
      }
    }
    .iconfont{
        // vertical-align:middle;
      }
  }
  .right-wrap{
    line-height: 24px;
    display: none;
    // visibility: hidden;
    gap:4px;
    .iconfont{
      cursor: pointer;
      padding: 4px;
      &:hover{
        border-radius: 6px;
        background: rgba(31, 35, 41, 0.08);
      }
    }
  }
}

.record-tags-cont {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  // flex-wrap: wrap;
  // margin-left: 4px;
  margin-top: 4px;
  // overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .tag-item {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 20px;
    padding: 1px 6px;
    border-radius: 4px;
    background: #DDEAFF;
    margin-right: 4px;
    margin-top: 4px;
    position: relative;
    z-index: 1;
    .tag-item-name {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      font-weight: bold;
      line-height: 18px;
      color: #1F2329;
      display: inline-block;
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .icon-icon_add1 {
      font-size: 16px;
      color: #646A73;
    }
    .icon-icon_close_med {
      font-size: 12px;
      margin-left: 6px;
      cursor: pointer;
      color: #2B2F36;
    }
  }
}
.creater-cont {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  // overflow-x: auto;
  display: flex;
  align-items: center;
  margin-top:8px;
  &::-webkit-scrollbar {
    display: none;
  }
  &.more {
    width: 100%;
    height: 32px;
    margin: 0;
    .avatar-cont {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }
    .person-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .more-person-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 50%;
    background: #F2F5FA;
    font-family: DINAlternate-Bold;
    font-size: 12px;
    font-weight: bold;
    color: #1F2329;
    cursor: pointer;
  }
  .avatar-item-cont {
    flex-shrink: 0;
    display:flex;
    align-items: center;
    padding: 2px 8px 2px 2px;
    border-radius: 40px;
    background: #F3F4F3;
    overflow: hidden;
    margin-right: 4px;
    .icon-icon_close_med {
      font-size: 12px;
      color: #646A73;
      margin-left: 4px;
      cursor: pointer;
    }
  }
  .avatar-item {
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 4px;
  }
  .user-name {
    font-size: 12px;
  }
  .avatar-cont {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    background: linear-gradient(180deg, #297AFF 0%, #0963F6 100%);
    .avatar-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .avatar-text {
      color: #fff;
      font-size: 12px;
      display: inline-block;
      scale: 0.75;
    }
  }
  .person-info {
    width: 119px;
    height: 38px;
    margin-left: 8px;
    .name {
      height: 20px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      line-height: 20px;
      color: #1F2329;
    }
    .dpt {
      height: 18px;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      line-height: 18px;
      color: #86909C;
    }
  }
}
.empty-tips {
  display: inline-block;
  width: 200px;
  padding: 12px;
  font-size: 12px;
  color: #86909C;
}
.more-user-item {
  width: 100%;
  height: 48px;
  padding: 0 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .delete-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      background: rgba(31, 35, 41, 0.08);
      .icon-icon_close_med {
        color: #2B2F36;
      }
    }
    .icon-icon_close_med {
      color: #8F959E;
      font-size: 14px;
    }
  }
}
.more-person-content {
  width: 210px;
  max-height: 248px;
  padding: 4px;
  border-radius: 6px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
}
.record-option-btn {
  width: 48px;
  min-width: 48px !important;
  padding: 0;
  font-size: 12px;
  border-radius: 6px !important;
}
.record-type-popover-cont {
  width: 144px;
  // max-height: 50vh;
  // overflow-y: auto;
  &.creater {
    width: 210px;
    border-radius: 6px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    .pop-select-item {
      height: auto;
      padding: 8px;
      justify-content: space-between;
    }
  }
  
  .pop-select-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .active-select {
    display: flex;
    justify-content: space-between;
    color: #3272FE !important;
    .iconfont {
      width: 20px;
      padding-left: 4px;
      flex-shrink: 0;
    }
  }
}
.type-popover {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-top: 4px;
  .record-type-cont {
    width: 72px;
    height: 24px;
    line-height: 24px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #86909C;
    padding: 0px 4px;
    box-sizing: border-box;
    border-radius: 6px;
    transition: all .2s;
    cursor: pointer;
    &:hover {
      background: rgba(31, 35, 41, 0.08);
    }
    .icon-icon_d_arrow {
      font-size: 12px;
      margin-left: 4px;
    }
  }
}
.fileTypeSelectCont {
  position: fixed;
  .arco-select-dropdown {
    min-width: 170px;
  }
  :deep(.arco-select-dropdown) {
    min-width: 170px;
  }
}
.cus-uploaded-file-list {
  .icon-cont {
    margin-left: 1px;
  }
  .file-items {
    display: flex;
  }
  .edit-file-item {
    width: auto !important;
    // max-width: 270px;
    overflow: hidden;
    justify-content: flex-start;
    &:hover {
      .file-type {
        display: none !important;
      }
      :deep(.arco-select-view-single) {
        .arco-select-view-suffix {
          display: flex !important;
        }
      }
    }
  }
  .select-type-cont {
    :deep(.arco-select-view-single) {
      max-width: 100px;
      height: 16px;
      border-radius: 2px;
      border: 0;
      padding: 0 4px;
      background: none;
      .arco-select-view-value {
        display: inline-block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &.showSuf {
        .arco-select-view-suffix {
          display: flex !important;
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      .arco-select-view-value {
        line-height: 16px;
        padding: 0;
        color: #333B44;
        font-weight: 400;
        font-size: 12px;
      }
      .arco-select-view-suffix {
        display: none !important;
        padding-left: 2px;
      }
    }
  }
  .file-item-left {
    width: auto !important;
  }
  .item-name-file-type {
    margin-right: 6px;
  }
  .file-name {
    max-width: 120px;
    flex-grow: 0;
    width: auto !important;
    color: #3272FE !important;
  }
  .shu {
    display: inline-block;
    width: 1px;
    height: 8px;
    border-left: 1px solid #E4E5EA;
    margin-left: 4px;
  }
  .file-type {
    display: block !important;
    max-width: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 4px;
  }
  .edit-file-item {
    margin-top: 0 !important;
  }
  .file-items {
    margin-bottom: 4px;
  }
}
.follow-text-btn-cont {
  position: sticky;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 6px 10px 10px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  &.mini {
    :deep() {
      .uploader-trigger {
        width: 28px;
        height: 28px;
        .trigger-empty {
          width: 100%;
        }
      }
      .emoji-icon-wrap {
        padding: 0 6px !important;
      }
    }
  }
  &.warp {
    // flex-direction: column;
    align-items: start;
    .left-options {
      // margin-bottom: 8px;
      // margin-left: 10px;
    }
  }
  &.out-submit-btn {
    flex-direction: row;
    align-items: center;
    position: relative;
    .action-btn-content {
      position: absolute;
      top: 56px;
      right: -12px;
    }
    .left-options {
      margin-bottom: 0;
      flex-shrink: 0;
    }
  }
  .releat-project {
    display: flex;
    align-items: center;
    .releat-title {
      display: flex;
      align-items: center;
      line-height: 28px;
      padding: 0px 6px;
      border-radius: 6px;
      font-size: 14px;
      color: #86909C;
      transition: all .2s;
      cursor: pointer;
      &:hover {
        background: rgba(31, 35, 41, 0.08);
      }
      .active {
        color: #1F2329;
      }
      .releat-icon {
        font-size: 16px;
        color: #646A73;
        margin-right: 6px;
      }
      .icon-icon_d_arrow {
        font-size: 12px;
        color: #8F959E;
        margin-left: 6px;
      }
    }
    :deep(.arco-select-view) {
      width: 100%;
      .arco-select-view-suffix {
        display: none;
      }
    }
  }
  .emojiTeleport-wrap{
    width: auto;
    height: 28px;
    width: 28px;
    position: relative;
    margin-left: 6px;
    >div{
      // width: 28px;
      display: inline-flex;
      :deep(.emoji-icon-wrap) {
        // margin-left: -35px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        padding: 0 12px;
        box-sizing: border-box;
        border-radius: 6px;
        transition: all .2s;
        cursor: pointer;
        background: none;
        transition: all .2s;
        position: absolute;
        top: 0;
        left: 0;
        
        &:hover {
          background-color: rgba(31, 35, 41, 0.08);
        }
        .emoji-icon  {
          margin-right: 6px;
        }
        .text {
          flex-shrink: 0;
        }
      }
    }
  }
  
  .left-options {
    display: flex;
    align-items: center;
    // margin-top: 2px;
    flex-shrink: 0;
    .divider {
      height: 16px;
      margin: 0 4px;
    }
  }
  .action-btn-content {
    margin-left: auto;
    // margin-top: 2px;
  }
  .create-select-disable{
    width: 98px;
    height: 28px;
    line-height: 28px;
    color: #fff;
    background: #e4e5eb;
    border-radius: 4px;
    text-align: center;
    cursor: not-allowed;
  }
  .arco-btn-primary {
    &.arco-btn-disabled {
      background-color: #E4E5EB;
      color: #fff;
      &:hover {
        background: #E4E5EB !important;
      }
    }
  }
}
.upload-file-icon-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 98px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 3px;
  font-size: 14px;
  color: #333B44;
  border: 1px solid #E4E5EB;
  cursor: pointer;
  transition: all .2s;
  &:hover {
    background: #F2F8FF;
  }
  .icon-icon_add {
    color: #888888;
    margin-right: 4px;
    font-size: 16px;
  }
}
.follow-text-content {
  width: 100%;
  max-height: calc(100vh - 182px);
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #E4E5EC;
  // padding:  0 0 10px 0;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
  &::-webkit-scrollbar-track {
    border-radius: 0 8px 8px 0;
    border-top: 1px solid #E4E5EC;
    border-bottom: 1px solid #E4E5EC;
  }
  &.top {
    max-height: calc(100vh - 48px);
    &::after {
      top: 8px;
    }
  }
  &.dragover {
    position: relative;
  }
  :deep(.arco-mention) {
    .arco-textarea-wrapper {
      padding-left: 0;
    }
  }
  :deep(.arco-textarea-wrapper) {
    border: 0;
    &:hover {
      background: none;
    }
    .arco-textarea {
      min-height: 40px;
      max-height: 71px;
      padding: 0;
    }
  }
  .record-drag-upload-mask {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    border-radius: 8px;
    background: rgba(238, 244, 255, 0.95);
    border: 1px dashed #126FFF;
    .tips-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 70px;
        margin-bottom: 6px;
      }
      span {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        color: #86909C;
      }
    }
  }
  .disabled-mask{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.arco-btn {
  min-width: 72px;
}
</style>
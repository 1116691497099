<template>
  <div ref="recordCellRef" class="last-record-card content">
    <div v-if="recordHtml" :class="['last-record', position, isShowAll? 'show-all':'' ]">
      <a-popover
        trigger="hover"
        :position="positionRender"
        :popup-container="isList? 'body':'.list-board-area'"
        :arrow-style="{background: '#1F2329', borderRadius: '2px'}"
        @popup-visible-change="hoverRecord"
        :style="cssVars"
        class="last-record-pop"
      >

        <span v-if="isList" :class="['text', isShowAll? 'all':'oneline']">{{ recordText }}</span>
        <span v-else class="text multiline" v-html="recordText"></span>
        <template #content>
          <div :class="['last-record-pop-detail', position]">
            <!-- 正文 -->
            <!-- <div @click="handleClickRecord" v-html="getHref(recordInfo.record)" class="artcle-text root-text"></div> -->
            <div @click="handleClickRecord" v-html="recordInfo.record" class="artcle-text root-text cus-font-size"></div>
            <!-- 分类 -->
            <div class="record-tags" v-if="currentTypeList?.length">
              <template v-for="tag in currentTypeList" :key="tag.id">
                <span class="tag-item">{{ tag.name }}</span>
              </template>
            </div>
            <!-- 文件 -->
            <ul v-if="recordInfo.fileInfos?.length" class="cus-uploaded-file-list last-record-file-list">
              <li class="file-items" v-for="file in recordInfo.fileInfos" :key="file">
                <div class="edit-file-item">
                  <div class="file-item-left" @click="open(file.url)">
                    <fileIcon class="item-name-file-type" :file="file"/>
                    <span class="file-name">
                      {{ file.fileName }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 共同创建者 -->
            <div class="persons-content" v-if="recordInfo.creators?.length">
              <i class="iconfont icon-icon_mb1 creator-icon" />
              <span>共同创建者：</span>
              <span>{{createorNameList.join('，')}}</span>
            </div>
            <!-- 创建时间 -->
            <div class="create-time">
              {{cusFormatDate(recordInfo.createdAt * 1)}}
            </div>
          </div>
        </template>
      </a-popover>
      <a-tooltip
        v-if="isEdit"
        content="添加跟进记录"
        background-color="#1F2329"
        position="top"
      >
        <i @click.stop="createRecord" class="iconfont icon-add2" />
      </a-tooltip>
    </div>
    <div v-else-if="isList" class="last-record">
      <span class="text">-</span>
      <a-tooltip
        v-if="isEdit"
        content="添加跟进记录"
        background-color="#1F2329"
        position="top"
      >
        <i @click.stop="createRecord" class="iconfont icon-add2" />
      </a-tooltip></div>
    <a-modal
      v-if="visible"
      v-model:visible="visible" 
      width="468px"
      top="100px"
      :footer="false"
      title="添加跟进记录"
      :mask-closable="false"
      :align-center="false"
      modal-class="last-record-create"
    >
      <div class="create-record-content">
        <releaseRecord
          ref="releaseRecordRef"
          from="followRecord"
          emojiTeleportName="createRecordEmojiTeleport"
          :defaultShowBtn="true"
          :typeList="currentTypeList"
          :recordTypeList="recordTypeList"
          :infoObj="pdata"
          :bCode="pdata.businessCode"
          :selfInfoId="userSelfInfo.id"
          @releaseHandler="releaseHandler"
          @cancleSub="cancleSub"
        ></releaseRecord>
      </div>
    </a-modal>
  <!-- 展示图片预览 -->
  <imgPreview
    v-if="showImgPreview"
    @cancel="showImgPreview=false"
    :imgUrls="imgPreviewUrls"
    :currIndex="imgPreviewIndex">
  </imgPreview>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue'
  import fileIcon from '@/components/fileIcon.vue'
  import { followRecordList, followRecordInsert ,taskSave} from '@/services/investment/followRecord.js'
  import {
    followRecordTypeQuery,
  } from '@/services/sysconfig/record.js'
  import imgPreview from '@/components/imgPreview/index.vue'
  import { cusFormatDate } from '@/utils/cusFormatDate.js'
  import { useElementBounding, useWindowSize } from '@vueuse/core'
  import releaseRecord from '@/views/components/releaseRecord/index.vue'
  import { useStore } from 'vuex'
  import { getDataTypeByBusinessCode } from '@/utils/enums.js';
  
  const props = defineProps({
    recordHtml: String,
    position: String,
    pdata: Object,
    showLabel: Boolean,
    label: String,
    isShowAll: Boolean
  })
  const emits = defineEmits(['refresh'])
  const isList = computed(() => {
    return props.position === 'list'
  })
  const recordText = computed(() => {
    let str = props.recordHtml?.replace(/<img[^<>]*?>/g, function(a,b,c){
        return '[图片]'
      }).replace(/<[^>]+>|&nbsp;/g, '')
    if (!isList.value && props.showLabel) {
      str = '<label class="label">'+ props.label+':</label>' + str
    }
    return str
  })
  const store = useStore()
  const userSelfInfo = computed(() => store.state.userSelfInfo)
  const isEdit = computed(() => {
    let res = false
    if (isList.value) {
      res = props.pdata?.permission?.haveEdit
    } else {
      res = props.pdata?.permission == 2 ? true : false  /* 1 只读 2编辑 */
    }
    return res
  })
  const recordPrefix = computed(() => {
    let str = ''
    if (!isList.value && !props.showLabel) {
      str = `<div>${props.label}：</div>`
    }
    return str + (recordInfo.value.userInfo?.name? recordInfo.value.userInfo?.name + '：' : '')
  })
  const recordCellRef = ref(null)
  const { top, right, width: eleWidth, x } = useElementBounding(recordCellRef)
  const { width: winWidth } = useWindowSize()

  const recordInfo = ref({})
  const currentTypeList = ref([])
  const createorNameList = ref([])
  const visible = ref(false)
  const recordTypeList = ref([])
  const releaseRecordRef = ref(null)
  const showImgPreview = ref(false)
  const imgPreviewUrls = ref([])
  const imgPreviewIndex = ref('')

  const positionRender = computed(() => {
    let positionAttr = 'top'
    if (top.value < 300) {
      // 窗口宽 - (当前cell的x坐标位置 + 添加跟进记录icon宽度) < 21
      if (winWidth.value - (x.value + eleWidth.value + 31) < 21) {
        positionAttr = 'br'
      } else {
        positionAttr = 'bottom'
      }
    } else {
      if (winWidth.value - (x.value + eleWidth.value + 31) < 21) {
        positionAttr = 'tr'
      }
    }
    
    return positionAttr
  })
  const cssVars = computed(() => {
    return {
      '--marginTop': isList.value? positionRender.value === 'top'? '-8px':'8px' : 0,
      '--marginLeft': ['br', 'tr'].includes(positionRender.value)? '31px':'11px'
    }
  })
  
  const createRecord = async() => {
    recordTypeList.value = await followRecordTypeQuery()
    visible.value = true
  }
  const getHref = (content) => {
    if (!content) {
      return '';
    }
    const htmlDom = new DOMParser().parseFromString(content, 'text/html')
      const domArr = Array.prototype.slice.call(htmlDom.getElementsByClassName('at-file')) // 将 getElementsByClassName 获取到的类数组转换为数组
      const imgArr = Array.prototype.slice.call(htmlDom.getElementsByClassName('local-img')) // 将 getElementsByClassName 获取到的类数组转换为数组
      if (!domArr.length && !imgArr.length) {
        const urlPattern = /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[()~#&\-=?+%/.\w]+)?/g;
        content = content.replace(/(<\/?a.*?>)/g, ''); // 去除a标签
        content = content.replace(urlPattern, (match) => {
          const urlHttp = match.indexOf('http://') && match.indexOf('https://');
          const url = urlHttp === -1 ? match.split('/')[0] : match.split('/')[2]; // 不包含http
          const href = urlHttp === -1 ? `https://${match}` : match; // 包含http
          return `<a target="_blank" href="${href}" style="color: #3298FF">${match}</a>`;
        });
      }
    return recordPrefix.value + content;
  }
  const handleClickRecord = (evt) => {
    if(evt.target && evt.target.className == 'local-img'){
      
      let tmpNode = evt.target.parentNode;
      while(tmpNode){
        if(tmpNode.className.indexOf('root-text') > -1){
          break;
        }else{
          tmpNode = tmpNode.parentNode;
        }
      }
      let imgNode = tmpNode.getElementsByClassName('local-img');
      imgPreviewUrls.value = Array.from(imgNode).map((ii)=>{ return ii.src });
      
      let tmpIndex = Array.from(imgNode).findIndex((ii)=>{ return ii.src == evt.target.src });
      imgPreviewIndex.value = tmpIndex > -1 ? tmpIndex : 0;

      showImgPreview.value = true;
    }
  }
  const hoverRecord = async(v) => {
    if (v) {
      const res = await followRecordList({
        data: {
          dataId: props.pdata.id,
          id: props.pdata.followRecordId,
          typeIds: []
        }
      })
      if (res?.list?.length) {
        recordInfo.value = res.list[0]
        // 分类
        recordTypeList.value = await followRecordTypeQuery()
        currentTypeList.value = (recordTypeList.value || []).filter(item => {
          return (recordInfo.value.typeIds || []).includes(item.id)
        })
        // 共同创建者
        createorNameList.value = (recordInfo.value.creators || []).reduce((result, item) => {
          result.push(item.userName)
          return result
        }, [])
      }
      
    }
  }
  const open = (url) => {
    window.open(url, '_blank')
  }
  const saveTask=(res,params, dataId, dataType, businessCode,taskFiles=[])=>{
      if(res?.task?.length){
        res.task.map((task,tIndex)=>{
          if(task?.isDeleted){
            return
          }
          var formData = new FormData();
            if(taskFiles[tIndex]){
              taskFiles[tIndex].map((file)=>{
                formData.append('file', file.file)
              })
              }
              
              let body={
                ...params.task[tIndex],
                id:task.id||null,
                dataId: dataId || null,
                businessCode: businessCode || null,
                assetTypeId:params.assetTypeId || null,
              }
              formData.append('body', JSON.stringify(body))
              // formData.append('file', files)
              // const saveOrUpdate =!isCreateTsk?taskSave:taskUpdate
              taskSave({
                data: formData,
              }).then(res => {
              })
          })
        }
    }
  const releaseHandler = async(params, dataId, dataType, businessCode,taskFiles=[]) => {
    
    const data = {
      dataId: props.pdata.id,
      dataType: getDataTypeByBusinessCode(props.pdata.businessCode),
      businessCode: props.pdata.businessCode,
      ...params
    }
    var form = new FormData();
    let imageNames = (data.record.match(/img_\d{13}/g) || []).map((ii)=>{
      return `${ii}`; 
    });
    if(Object.keys(data.pastePhotoMap || {}).length > 0){
      for(let ii of imageNames){
        if(data.pastePhotoMap[ii]){
          form.append(`images`, data.pastePhotoMap[ii]);
        }
      }
    }
    delete data.pastePhotoMap;
    data.imageNames = imageNames.map((ii)=>{ return `$$${ii}$$` });

    console.log(data);
    form.append(`body`, JSON.stringify(data));
    try {
      const followRecord = await followRecordInsert({
        data: form,
      })
      const res = await followRecordList({
        data: {
          dataId: props.pdata.id,
          id: followRecord?.id,
          typeIds: []
        }
      })
      
      if (res) {
        saveTask(res.list[0],params, props.pdata.id, null, props.pdata.businessCode,taskFiles)
        const userName =  res.list[0].userInfo?.name? res.list[0].userInfo?.name + '：' : ''
        emits('refresh', userName + params.record, followRecord?.id)
        visible.value = false
      }
      releaseRecordRef.value.btnLoading = false
    } catch (error) {
      releaseRecordRef.value.btnLoading = false
    }
    
  }
  const cancleSub = () => {
    visible.value = false
  }
</script>

<style lang="scss">
  @import '@/assets/styles/var.scss';
  @import '@/assets/styles/mixin.scss';
  .last-record-pop {
    &.arco-trigger-popup {
      margin-left: var(--marginLeft);
      margin-top: var(--marginTop);
    }
    .arco-popover-popup-content {
      width: 320px !important;
      background: #1F2329;
      border-radius: 8px;
      color: #fff;
      padding: 0 !important;
      .arco-popover-content {
        margin: 0;
      }
    }
  }
  .last-record-card {
    width: 100%;
    .last-record {
      display: flex;
      // align-items: center;
      width: 100%;
      &.show-all {
        padding: 9px;
        max-height: 157px;
        overflow: auto;
        word-break: break-all;
      }
      &.board {
        align-items: flex-start;
        margin-bottom: 8px;
        .text {
          font-size: 12px;
          line-height: 18px;
        }
        .iconfont {
          margin-top: 1px;
        }
      }
      .text {
        color: #1f2329;
        margin-right: 4px;
        label {
          color: #4E5969;
          margin-right: 6px;
        }
        &.oneline {
          @include ellipsis-oneline(calc(100% - 22px));
        }
        &.multiline {
          @include ellipsis-lines(calc(100% - 22px), 3);
        }
        &.all {

        }
      }
      .iconfont {
        margin-left: auto;
        cursor: pointer;
        color: $icon-color-gray1;
        margin-top: 2px;
      }
    }
  }
  .last-record-pop-detail {
    margin: 10px 4px 10px 12px;
    padding-right: 8px;
    max-height: 184px;
    overflow: auto;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    &::-webkit-scrollbar-track-piece {
      background: #1F2329;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
    }
    .record-tags {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 4px;
      .tag-more {
        height: 20px;
        display: flex;
        align-items: center;
        padding: 1px 6px;
        border-radius: 4px;
        background: #EEF0F1;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        font-weight: bold;
        color: #1F2329;
        margin-bottom: 4px;
        cursor: pointer;
      }
      .tag-item {
        height: 20px;
        line-height: 20px;
        display: flex;
        padding: 1px 6px;
        border-radius: 4px;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        color: #1F2329;
        background: #DDEAFF;
        margin-bottom: 4px;
        margin-right: 4px;
      }
    }
    .cus-uploaded-file-list.last-record-file-list {
      margin-bottom: 0px;
      .icon-cont {
        margin-left: 1px;
      }
      .file-items {
        display: flex;
      }
      .select-type-cont {
        .arco-select-view-single {
          max-width: 100px;
          height: 16px;
          border-radius: 2px;
          border: 0;
          padding: 0 4px;
          background: none;
          .arco-select-view-value {
            display: inline-block;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          &.showSuf {
            .arco-select-view-suffix {
              display: flex !important;
            }
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
          .arco-select-view-value {
            line-height: 16px;
            padding: 0;
            color: #333B44;
            font-weight: 400;
            font-size: 12px;
          }
          .arco-select-view-suffix {
            display: none !important;
            padding-left: 2px;
          }
        }
      }
      .file-item-left {
        // width: auto !important;
        width: 100%;
        img {
          width: 16px;
          margin-right: 4px;
        }
      }
      .item-name-file-type {
        margin-right: 6px;
      }
      .file-name {
        width: 100%;
        // max-width: 120px;
        flex-grow: 0;
        width: auto !important;
        color: #fff !important;
      }
      .shu {
        display: inline-block;
        width: 1px;
        height: 8px;
        border-left: 1px solid #E4E5EA;
        margin-left: 4px;
      }
      .file-type {
        display: block !important;
        max-width: 100px;
        flex-grow: 0;
        flex-shrink: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 4px;
      }
      .edit-file-item {
        margin-top: 0 !important;
        padding: 0;
        &:hover {
          background-color: transparent !important;
        }
      }
      .file-items {
        margin-bottom: 0px;
      }
    }
    .follow-text-contents {
      width: calc(100% + 36px);
      border-radius: 4px;
      // border: 1px solid #3272FE;
      // padding: 8px 12px;
      box-sizing: border-box;
      position: relative;
      left: -36px;
      &.mb {
        margin-bottom: 8px;
        margin-top: 12px;
      }
      .arco-textarea-wrapper {
        border: 0;
        &:hover {
          background: none;
        }
        .arco-textarea {
          min-height: 40px;
          max-height: 71px;
          padding: 0;
        }
      }
    }
    .artcle-text {
      // width: 100%;
      color: #fff;
      font-size: 12px;
      // line-height: 18px;
      // margin-bottom: 4px;
      word-wrap: break-all;
      word-break: normal;
      white-space: pre-wrap;
      &.cus-font-size {
        * {
          font-family: PingFang SC !important;
          font-size: 12px !important;
          color: #fff !important;
          background: transparent !important;
        }
        a {
          color:#3272FE !important;
        }
        span[data-w-e-type="mention"] {
          color:#3272FE !important;
        }
      }
      ul {
        list-style: disc;
        list-style-position: inside;
        li {
          &::marker {
            color: #3272FE;
            margin-right: 8px;
          }
        }
      }
      ol {
        list-style: decimal;
        list-style-position: inside;
        li {
          s {
            margin-left: 8px;
          }
          &::marker {
            color: #3272FE;
            margin-right: 8px;
          }
        }
      }
      blockquote {
        background: transparent;
        border-left: 2px solid #BBBFC3;
        display: block;
        font-size: 100%;
        line-height: 22px;
        margin: 6px 0;
        padding-left: 8px;
        color: #646A73;
      }
      span[data-w-e-type="mention"] {
        color: #3272FE;
        margin: 0 3px;
      }
      .at-text {
        color: $theme-color;
      }
      .local-img {
        height: 40px;
        cursor: pointer;
      }
    }
    .persons-content {
      width: 100%;
      // display: flex;
      // align-items: center;
      margin-top: 4px;
      color: #8F959E;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);;
      .creator-icon {
        font-size: 14px;
        margin-right: 4px;
      }
    }
    .create-time {
      color: rgba(255, 255, 255, 0.7);
      margin-top: 4px;
    }
    .name-text {
      height: 20px;
      color: #333B44;
      font-size: 12px;
      line-height: 20px;
      margin-top: 4px;
      margin-bottom: 8px;
      &.has-person {
        margin-top: 0;
        margin-bottom: 6px;
      }
      &.flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .user-infor {
        display: flex;
      }
      .type-name {
        display: inline-block;
        max-width: 72px;
        height: 20px;
        padding: 1px 6px;
        border-radius: 4px;
        background: #EEF0F1;
        font-family: PingFangSC-Medium;
        font-size: 12px;
        font-weight: bold;
        color: #1F2329;
        margin-left: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .time {
        color: #959AA2;
        font-weight: normal;
        font-size: 12px;
        font-family: PingFangSC;
        margin-left: 6px;
        &.small {
          margin-left: 4px;
        }
      }
      .option-icon-cont {
        display: flex;
        align-items: center;
        font-weight: normal;
        // &.pr {
        //   padding-right: 8px;
        // }
        .comment-icon-cont {
          display: flex;
          align-items: center;
          color: #959AA2;
          margin-right: 4px;
          &.open {
            color: $theme-color;
            .iconfont {
              color: $theme-color;
            }
          }
        }
        .iconfont {
          display: inline-block;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            background-color: rgba(0, 0, 0, 0.06);
          }
        }
      }
    }
  }
  .last-record-create {
    .create-record-content {
      padding-bottom: 30px;
    }
    .arco-modal-header {
      border-bottom: none;
      height: 50px;
      .arco-icon-hover {
        font-size: 20px;
        color: $icon-color-gray2;
      }
    }
  }
</style>

import dayjs from 'dayjs'
export const timeOptions = [
  { value: 0, label: '00:00' },
  { value: 30, label: '00:30' },
  { value: 60, label: '01:00' },
  { value: 90, label: '01:30' },
  { value: 120, label: '02:00' },
  { value: 150, label: '02:30' },
  { value: 180, label: '03:00' },
  { value: 210, label: '03:30' },
  { value: 240, label: '04:00' },
  { value: 270, label: '04:30' },
  { value: 300, label: '05:00' },
  { value: 330, label: '05:30' },
  { value: 360, label: '06:00' },
  { value: 390, label: '06:30' },
  { value: 420, label: '07:00' },
  { value: 450, label: '07:30' },
  { value: 480, label: '08:00' },
  { value: 510, label: '08:30' },
  { value: 540, label: '09:00' },
  { value: 570, label: '09:30' },
  { value: 600, label: '10:00' },
  { value: 630, label: '10:30' },

  { value: 660, label: '11:00' },
  { value: 690, label: '11:30' },
  { value: 720, label: '12:00' },
  { value: 750, label: '12:30' },
  { value: 780, label: '13:00' },
  { value: 810, label: '13:30' },
  { value: 840, label: '14:00' },
  { value: 870, label: '14:30' },
  { value: 900, label: '15:00' },
  { value: 930, label: '15:30' },
  { value: 960, label: '16:00' },
  { value: 990, label: '16:30' },
  { value: 1020, label: '17:00' },
  { value: 1050, label: '17:30' },
  { value: 1080, label: '18:00' },
  { value: 1110, label: '18:30' },
  { value: 1140, label: '19:00' },
  { value: 1170, label: '19:30' },
  { value: 1200, label: '20:00' },
  { value: 1230, label: '20:30' },
  { value: 1260, label: '21:00' },
  { value: 1290, label: '21:30' },
  { value: 1320, label: '22:00' },
  { value: 1350, label: '22:30' },
  { value: 1380, label: '23:00' },
  { value: 1410, label: '23:30' },
]
export const deadlineOptions2 = [
  { value: -1080, label: '当天 18:00' },
  { value: 900, label: '截止前 1 天 9:00' },
  { value: 2340, label: '截止前 2 天 9:00' },
  { value: 9540, label: '截止前 1 周 9:00' },
]
export const deadlineOptions1 = [
  { value: 0, label: '任务截止时' },
  { value: 5, label: '截止前 5 分钟' },
  { value: 15, label: '截止前 15 分钟' },
  { value: 30, label: '截止前 30 分钟' },
  { value: 60, label: '截止前 1 小时' },
  { value: 120, label: '截止前 2 小时' },
  { value: 1440, label: '截止前 1 天' },
  { value: 2880, label: '截止前 2 天' },
  { value: 10080, label: '截止前 1 周' },
]
export const transformTaskData =function(tasks){
    let arr=[]
    tasks.map((task)=>{
      let info={
        summary:task.summary,
        dueTime:undefined,
        id:task.id,
        listGuid:task.listGuid,
        assignee:[],
        follower:[],
        checkbox:false,
        fileList:task.file||[],
        deleteFileGuid:task.deleteFileGuid || [],
        isDeleted:task.isDeleted,
        assignee_related:task.assignee_related
      }
     
      if(task?.completedAt&&task?.completedAt>0){
        info.completed=true
      }
      if(task.due&&task.due?.timestamp){
        info.due=dayjs(Number(task.due.timestamp)).format('YYYY-MM-DD')
        if(!task.due.is_all_day){
          let timestamp=Number(task.due.timestamp)
          let num=dayjs(timestamp).valueOf()- dayjs(dayjs(timestamp).format('YYYY-MM-DD')).valueOf()
          info.dueTime=num /(1000*60)
          info.checkbox=true
        }
      }
      if(task.reminders?.length&&task.reminders[0].relative_fire_minute!==null){
        info.reminders=task.reminders[0].relative_fire_minute
      }
      if(task?.members?.length){
        info.orginMembers= task.members
        task.members.map((item)=>{
          let isCompleted= task?.assignee_related?.find(({userId})=>{
            return item.userId==userId
          })
          // if(task.assignee_related){
          //   debugger
          // }
          let obj={...item,orginId:item.id,id:item.userId,}
          if(isCompleted)obj.isCompleted=true
          item.role=='assignee'?info.assignee.push(obj):info.follower.push(obj)
        })
      }
      arr.push(info)
    })
    return arr
}
export const assigneeName = function(assignee){
  let name=''
  assignee.map(function(item,index){
    name+= (index > 0 ? '、' :' ') + item.name
  })
  return name
}
export const getTimeText = function(info){
  let text = null
  if (info.checkbox) {
    text = timeOptions.find(({ value }) => info.dueTime == value)?.label
    if(!text&&info.dueTime){
      //  飞书自定义的时间 options没有
      let hour=parseInt(info.dueTime/60)<10?( '0' + parseInt(info.dueTime/60) ):parseInt(info.dueTime/60)
      let minute=(info.dueTime%60)<10?( '0' + info.dueTime%60 ):parseInt(info.dueTime%60 )
      return `${hour}:${minute}`
    }
  }
  return text || null
}
export const getTipsText = function(info){
    let text = (info.checkbox ? deadlineOptions1 : deadlineOptions2).find(
      ({ value }) => info?.reminders == value,
    )?.label
    return text?text+'提醒':null
}
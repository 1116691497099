import { ajax } from '@/utils/request';

// 获取动态设置列表
export async function getNotifySetList(extra) {
  const result = await ajax({
    method: 'get',
    url: '/chat/config/list',
    ...extra
  });
  return result;
}
// 获取动态设置列表（不需要验证权限，永远都能拉到配置）
export async function getNotifySetListWithOutDataRight(extra) {
  const result = await ajax({
    method: 'get',
    url: '/chat/config/listWithOutDataRight',
    ...extra
  });
  return result;
}
// 获取舆情监测开启状态
export async function publicInformationQueryIsOpen(extra) {
  const result = await ajax({
    method: 'get',
    url: '/publicOpinion/publicInformation/queryIsOpen',
    ...extra
  });
  return result;
}

// 修改动态设置列表
export async function updateNotify(extra) {
  const result = await ajax({
    method: 'post',
    url: '/chat/config/update',
    ...extra
  });
  return result;
}
// 舆情监测开启、关闭
export async function publicInformationSave(extra) {
  const result = await ajax({
    method: 'post',
    url: '/publicOpinion/publicInformation/save',
    ...extra
  });
  return result;
}

// 根据应用名称获取应用信息, name: 项目管理/已投公司/基金管理/投资者管理
export async function getAppInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: '/app/query',
    ...extra
  });
  return result;
}

// 申请数据权限
export async function applyDataAuth(extra) {
  const result = await ajax({
    method: 'post',
    url: '/message/apply/permission',
    ...extra
  });
  return result;
}

// 查询是否同意过协议和协议内容
export async function fetchProtocol() {
  const result = await ajax({
    method: 'get',
    url: '/agreement/query',
  })
  return result
}

// 点击同意协议
export async function agreeProtocol() {
  const result = await ajax({
    method: 'post',
    url: '/agreement/agree',
  })
  return result
}

// 根据app查询侧边栏打开状态
export async function appOpenQueryByApp(extra) {
  const result = await ajax({
    method: 'post',
    url: '/appOpen/queryByApp',
    ...extra
  });
  return result;
}
// 更新侧边栏打开状态
export async function appOpenUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/appOpen/update',
    ...extra
  });
  return result;
}

// 根据appId获取对应应用(无需登录token)
export async function getAppNameById(extra) {
  const result = await ajax({
    method: 'get',
    url: '/app/getName',
    ...extra
  });
  return result;
}

// 查询任务清单获取打开任务清单的 url
export async function taskListQuery(data) {
  const result = await ajax({
    method: 'post',
    url: '/task/list/query',
    ...data
  });
  return result;
}
// 查询任务清单列表 根据 code 判断是否开启了权限
export async function taskList(data) {
  const result = await ajax({
    method: 'post',
    url: '/task/list',
    ...data
  },{
    getContext: true
  });
  return result;
}
// 创建清单
export async function taskListCreate(data) {
  const result = await ajax({
    method: 'post',
    url: '/task/list/create',
    ...data
  });
  return result;
}